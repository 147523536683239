<template>
    <div>
        <main class="main-content">
    <!--== Start Page Header Area Wrapper ==-->
    

    <!--== Start Faq Area Wrapper ==-->
    <section class="page-not-found-area">
      <div class="container pt--0 pb--0">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="page-not-found-wrap">
              <div class="page-not-found-thumb">
                <img src="static/picture/404.webp" alt="Image">
              </div>
              <div class="page-not-found-content">
                <h2 class="title">糟了，内容不见了！</h2>
                <a class="btn-theme" href="/#"><i class="icofont-long-arrow-left"></i>返回首页</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--== End Faq Area Wrapper ==-->
  </main>
    </div>
</template>
<script>
import { mixin } from '../../mixins'
export default {
    mixins: [mixin],
    data(){
        return{

        }
    },
    created(){  

    },
    watch: {			
       
        
    },
    methods:{
        
    }
}
</script>
<style lang="less" scoped>
   
</style>